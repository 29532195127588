<template>
  <section class="main">
    <div class="d-flex flex-wrap align-items-center justify-content-between">
      <div class="table-header">Список баннеров</div>
      <router-link class="btn btn-success" to="/create-banner">Добавить</router-link>
    </div>
    <table class="table table-striped table-hover">
      <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col">Название</th>
        <th scope="col">Изображение</th>
        <th scope="col">Порядок</th>
        <th scope="col"></th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(item, index) in bannerList" :key="item.id">
        <th scope="row">{{ index + 1 }}</th>
        <td class="pointer" @click="editBanner(item.id)">{{ item.name }} </td>
        <td class="pointer" @click="editBanner(item.id)"><img class="table-img-banner" :src="item.image_pc" :alt="item.name"/></td>
        <td><input class="very-small" type="number" @change="editSort(item.id, $event.target.value)" placeholder="Порядок" :value="item.sort"></td>
        <td class="actions">
          <a class="pointer" @click="editBanner(item.id)">
            <i class="fas fa-pen"></i>
          </a>
          <a class="pointer" @click="deleteBanner(item.id)">
            <i class="fas fa-trash"></i>
          </a>
        </td>
      </tr>
      </tbody>
    </table>
    <div class="row">
      <div class="col text-center">
        <a class="btn btn-primary" @click="getMore">Загрузить ещё</a>
      </div>
    </div>
  </section>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'list-banners',
  data: function () {
    return {
      visible: 12,
    }
  },
  computed: mapGetters(['bannerList']),
  async mounted() {
    this.getBannerList();
  },
  methods: {
    getMore: function(){
      this.visible += 12;
      this.getBannerList();
    },
    getBannerList: function(){
      this.$store.dispatch('getBannerList', this.visible);
    },
    editSort: function(id, value){
      this.$store.dispatch('editSortBanner', {'id': id, 'sort': value});
    },
    editBanner: function(id){
      this.$router.push({ name: 'edit-banner', params: { id: id } })
    },
    deleteBanner: function(id){
      if(confirm('Вы уверены что хотите удалить баннер?')){
        this.$store.dispatch('deleteBanner', id);
      }
    },
  },
}
</script>